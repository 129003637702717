
export default {
    BSC:{
        USDT:{
            name:"USDT",
            address:"0x55d398326f99059fF775485246999027B3197955",
            decimals:18
        }
    },
    ETH:{
        USDT:{
            name:"USDT",
            address:"0xdAC17F958D2ee523a2206206994597C13D831ec7",
            decimals:18
        }
    },
    TRC:{
        USDT:{
            name:"USDT",
            address:"TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
            decimals:6
        }
    }
}
